import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Text } from '@twilio-paste/text';
import { Blockquote } from '../../components/Blockquote';
import { TokensList } from '../../components/tokens-list';
export const pageQuery = graphql`
  {
    allPasteTokenDefault {
      edges {
        node {
          id
          tokens {
            categoryName
            tokens {
              category
              comment
              name
              originalValue
              type
              value
            }
          }
        }
      }
    }
    allPasteTokenSendGrid {
      edges {
        node {
          id
          tokens {
            categoryName
            tokens {
              category
              comment
              name
              originalValue
              type
              value
            }
          }
        }
      }
    }
    allPasteTokenConsole {
      edges {
        node {
          id
          tokens {
            categoryName
            tokens {
              category
              comment
              name
              originalValue
              type
              value
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Paragraph = makeShortcode("Paragraph");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Design Tokens`}</h1>
    <Blockquote name="Salesforce UX" source="Lightning Design System" sourceUrl="https://www.lightningdesignsystem.com/design-tokens/" mdxType="Blockquote">
  Design tokens are the visual design atoms of the design system — specifically, they are named entities that store
  visual design attributes.
    </Blockquote>
    <p>{`Tokens are named for their use case, rather than their value. For example `}<inlineCode parentName="p">{`$color-background-error`}</inlineCode>{` is named to indicate the appropriate background color for an error, as opposed to `}<inlineCode parentName="p">{`$color-background-red`}</inlineCode>{` which is less specific and could be used in a number of ways depending on cultural context and design language. This makes their intended use clear and intentional, and allows us to develop a scalable and consistent visual language.`}</p>
    <p>{`In Paste, `}<strong parentName="p">{`1 rem unit = 16 pixels`}</strong>{`.`}</p>
    <Box backgroundColor="colorBackground" borderRadius="borderRadius20" padding="space80" marginTop="space100" marginBottom="space100" maxWidth="size40" mdxType="Box">
  <Paragraph mdxType="Paragraph">
    Use design tokens in your Sketch files through Abstract. Get the{' '}
    <Anchor href="https://share.goabstract.com/06c5a50d-87df-4f40-bfe8-e92cc55de4aa" mdxType="Anchor">Console theme</Anchor> and{' '}
    <Anchor href="https://share.goabstract.com/2a0af593-d939-463e-9763-6976bbbfbdbc" mdxType="Anchor">SendGrid theme</Anchor>.
  </Paragraph>
    </Box>
    <TokensList defaultTokens={props.data.allPasteTokenDefault.edges} consoleTokens={props.data.allPasteTokenConsole.edges} sendgridTokens={props.data.allPasteTokenSendGrid.edges} mdxType="TokensList" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      